import Layout from "@/layout/index.vue";

export default {
  path: "/category",
  component: Layout,
  meta: {
    roles: ["admin", "work-manager"],
  },
  children: [
    {
      path: "/category",
      name: "Category",
      component: () => import("@/views/category/index.vue"),
      meta: {
        title: "Category",
      },
    },
    {
      path: "/category/create",
      name: "CreateCategory",
      component: () => import("@/views/category/create.vue"),
      meta: {
        title: "Create Category",
      },
    },
    {
      path: "/category/edit/:id",
      name: "EditCategory",
      component: () => import("@/views/category/edit.vue"),
      meta: {
        title: "Edit Category",
      },
    },
  ],
};
