<template>
  <div class="layout-block">
    <header>
      <navbar></navbar>
    </header>
    <app-main></app-main>
  </div>
</template>

<script>
import { AppMain, Navbar } from "./components";

export default {
  name: "Layout",
  components: { Navbar, AppMain },
};
</script>

<style lang="scss" scoped></style>
