import Layout from "@/layout/index.vue";

export default {
  path: "/artist",
  component: Layout,
  meta: {
    roles: ["admin", "work-manager"],
  },
  children: [
    {
      path: "/artist",
      name: "Artist",
      component: () => import("@/views/artist/index.vue"),
      meta: {
        title: "Artist",
      },
    },
    {
      path: "/artist/create",
      name: "CreateArtist",
      component: () => import("@/views/artist/create.vue"),
      meta: {
        title: "Create Artist",
      },
    },
    {
      path: "/artist/edit/:id",
      name: "EditArtist",
      component: () => import("@/views/artist/edit.vue"),
      meta: {
        title: "Edit Artist",
      },
    },
    {
      path: "/artist/detail/:id",
      name: "DetailArtist",
      component: () => import("@/views/artist/detail.vue"),
      meta: {
        title: "Detail Artist",
      },
    },
  ],
};
