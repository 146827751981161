import router from "@/router";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";
import { Message } from "element-ui";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login", "/404"];

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

/**
 * This method is using to keep router query params.
 * Currently query params include p(page).
 *
 * @param from
 * @param to
 * @returns {boolean}
 */
function keepQueryParams(from, to) {
  const fromPath = from.path.split("/");
  const toPath = to.path.split("/");

  // if path is the same, means just change the page.
  if (from.path === to.path) {
    return true;
  }

  // handle route remain query logic
  if (fromPath[1] === toPath[1]) {
    const remainRoute = ["create", "edit", "detail"];

    return (
      remainRoute.includes(toPath[2]) ||
      remainRoute.includes(fromPath[2]) ||
      toPath[2] === fromPath[2] // handle second layer of router comparison, ex: activity/category/detail
    );
  }

  return false;
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta["title"]);

  const hasToken = getToken();

  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      const hasRole = store.getters.role;

      if (hasRole) {
        if (
          !hasQueryParams(to) &&
          hasQueryParams(from) &&
          keepQueryParams(from, to)
        ) {
          next({ ...to, query: from.query });
          NProgress.done();
        } else {
          next();
          NProgress.done();
        }
      } else {
        try {
          const { role } = await store.dispatch("user/getInfo");

          const accessRoutes = await store.dispatch(
            "permission/generateRoutes",
            role
          );

          if (accessRoutes.length === 0) {
            throw new Error(
              `There has no access routes for ${role.replace(/-/g, " ")}`
            );
          }

          accessRoutes.forEach((route) => {
            router.addRoute(route);
          });

          if (role === "admin") {
            next({ ...to, replace: true });
          } else if (role === "work-manager") {
            next({ path: "/work", replace: true });
          } else if (role === "activity-manager") {
            next({ path: "/activity", replace: true });
          }
        } catch (error) {
          await store.dispatch("user/resetToken");
          Message.error(error || "Has Error");
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
