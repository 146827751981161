import Layout from "@/layout/index.vue";

export default {
  path: "/participant",
  component: Layout,
  meta: {
    roles: ["admin", "activity-manager"],
  },
  children: [
    {
      path: "/participant",
      name: "Participant",
      component: () => import("@/views/participant/main/index.vue"),
      meta: {
        title: "Participant",
      },
    },
    {
      path: "/participant/detail/:id",
      name: "DetailParticipant",
      component: () => import("@/views/participant/main/detail.vue"),
      meta: {
        title: "Detail Participant",
      },
    },
    {
      path: "/participant/edit/:id",
      name: "EditParticipant",
      component: () => import("@/views/participant/main/edit.vue"),
      meta: {
        title: "Edit Participant",
      },
    },

    // participant record
    {
      path: "/participant/record/",
      name: "ParticipantRecord",
      component: () => import("@/views/participant/record/index.vue"),
      meta: {
        title: "Participant Record",
      },
    },
    {
      path: "/participant/record/edit/:id",
      name: "EditParticipantRecord",
      component: () => import("@/views/participant/record/edit.vue"),
      meta: {
        title: "Edit Participant Record",
      },
    },
    {
      path: "/participant/record/detail/:id",
      name: "DetailParticipantRecord",
      component: () => import("@/views/participant/record/detail.vue"),
      meta: {
        title: "Detail Participant Record",
      },
    },
  ],
};
