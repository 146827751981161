const state = {
  progress: [],
  queue: 0,
  cancel: [],
};

const mutations = {
  SET_PROGRESS: (state, progress) => {
    const find = state.progress.findIndex((el) => el.queue === progress.queue);
    if (find === -1) {
      // cannot find the index of queue means it's new queue
      state.progress.push(progress);
      return;
    }
    state.progress[find].progress = progress.progress;
  },
  SET_QUEUE: (state, queue) => {
    state.queue = queue + 1;
  },
  REMOVE_PROGRESS: (state, queue) => {
    state.progress = state.progress.filter((el) => el.queue !== queue);
    state.cancel = state.cancel.filter((el) => el.queue !== queue);
  },
  CANCEL_PROGRESS: (state, cancel) => {
    state.cancel.push(cancel);
  },
};

const actions = {
  addProgress({ commit }, progress) {
    return new Promise((resolve) => {
      commit("SET_PROGRESS", progress);
      resolve("update progress");
    });
  },
  removeProgress({ commit }, queue) {
    return new Promise((resolve) => {
      commit("REMOVE_PROGRESS", queue);
      resolve("remove success");
    });
  },
  cancelProgress({ commit }, cancel) {
    return new Promise((resolve) => {
      commit("CANCEL_PROGRESS", cancel);
      resolve("cancel success");
    });
  },
  setQueue({ commit }, queue) {
    return new Promise((resolve) => {
      commit("SET_QUEUE", queue);
      resolve("update queue");
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
