import Layout from "@/layout/index.vue";

export default {
  path: "/schedule",
  component: Layout,
  meta: {
    roles: ["admin", "activity-manager", "work-manager"],
  },
  children: [
    {
      path: "/schedule",
      name: "Schedule",
      component: () => import("@/views/schedule/index.vue"),
      meta: {
        title: "Schedule",
      },
    },
    {
      path: "/schedule/create",
      name: "CreateSchedule",
      component: () => import("@/views/schedule/create.vue"),
      meta: {
        title: "Create Schedule",
      },
    },
    {
      path: "/schedule/edit/:id",
      name: "EditSchedule",
      component: () => import("@/views/schedule/edit.vue"),
      meta: {
        title: "Edit Schedule",
      },
    },
    {
      path: "/schedule/detail/:id",
      name: "DetailSchedule",
      component: () => import("@/views/schedule/detail.vue"),
      meta: {
        title: "Detail Schedule",
      },
    },

    {
      path: "/schedule/location",
      name: "ScheduleLocation",
      component: () => import("@/views/schedule/location/index.vue"),
      meta: {
        title: "Schedule Location",
      },
    },
    {
      path: "/schedule/location/create",
      name: "CreateScheduleLocation",
      component: () => import("@/views/schedule/location/create.vue"),
      meta: {
        title: "Create Schedule Location",
      },
    },
    {
      path: "/schedule/location/edit/:id",
      name: "EditScheduleLocation",
      component: () => import("@/views/schedule/location/edit.vue"),
      meta: {
        title: "Edit Schedule Location",
      },
    },
  ],
};
