<template>
  <div class="pagination-block flex-center" v-if="total !== 0">
    <el-pagination
      background
      :current-page="page + 1"
      :page-size="limit"
      layout="prev, pager, next"
      :total="total"
      @current-change="handleChangePage"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "TablePagination",
  props: {
    total: {
      required: true,
      type: Number,
      default: 0,
    },
    limit: {
      required: true,
      type: Number,
      default: 25,
    },
    page: {
      required: true,
      type: Number,
      default: 1,
    },
  },
  methods: {
    async handleChangePage(value) {
      const { p: _p, ...query } = this.$route.query;

      await this.$router.push({ query: { p: value - 1, ...query } });
      this.$emit("change-page", value - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-block {
  padding: 24px 0;
}
</style>
