import request from "@/utils/request";

/**
 * Login the user.
 *
 * @param {object} data
 * @param {string} data.username
 * @param {string} data.password
 */
export function login(data) {
  return request({
    url: "api/v1/users/login",
    method: "POST",
    data,
  });
}

/**
 * Logout the user.
 */
export function logout() {
  return request({
    url: "api/v1/users/logout",
    method: "POST",
  });
}

/**
 * Get current user information by token.
 */
export function getInfo() {
  return request({
    url: "api/v1/users/info",
    method: "GET",
  });
}

/**
 * Get user.
 *
 * @param {string} user_id
 */
export function getUser(user_id) {
  return request({
    url: `api/v1/users/${user_id}`,
    method: "GET",
  });
}

/**
 * Get users.
 *
 * @param {object} [params]
 * @param {string} [params.search]
 */
export function getUsers(params) {
  return request({
    url: "api/v1/users",
    method: "GET",
    params,
  });
}

/**
 * Get user roles.
 */
export function getRoles() {
  return request({
    url: "api/v1/users/roles",
    method: "GET",
  });
}

/**
 * Get user with pagination.
 *
 * @param {object} [params]
 * @param {number} [params.limit]
 * @param {number} [params.page]
 */
export function getUsersPagination(params) {
  return request({
    url: "api/v1/users/page",
    method: "GET",
    params,
  });
}

/**
 * Create user.
 *
 * @param {object} data
 * @param {string} data.username
 * @param {string} data.password
 * @param {string} data.name
 * @param {string} [data.phone]
 * @param {boolean} data.role
 * @param {boolean} data.active
 */
export function createUser(data) {
  return request({
    url: "api/v1/users",
    method: "POST",
    data,
  });
}

/**
 * Update user.
 *
 * @param {string} user_id
 * @param {Object} data
 * @param {string} [data.password]
 * @param {string} [data.name]
 * @param {boolean} [data.active]
 * @param {string} [data.role]
 * @param {string} [data.phone]
 */
export function updateUser(user_id, data) {
  return request({
    url: `api/v1/users/${user_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Check user email exists.
 *
 * @param {object} params
 * @param {string} params.email
 */
export function checkEmailExists(params) {
  return request({
    url: "api/v1/users/email/exists",
    method: "GET",
    params,
  });
}
