import Layout from "@/layout/index.vue";

export default {
  path: "/work",
  component: Layout,
  meta: {
    roles: ["admin", "work-manager"],
  },
  children: [
    {
      path: "/work",
      name: "Work",
      component: () => import("@/views/work/main/index.vue"),
      meta: {
        title: "Work",
      },
    },
    {
      path: "/work/create",
      name: "CreateWork",
      component: () => import("@/views/work/main/create.vue"),
      meta: {
        title: "Create Work",
      },
    },
    {
      path: "/work/edit/:id",
      name: "EditWork",
      component: () => import("@/views/work/main/edit.vue"),
      meta: {
        title: "Edit Work",
      },
    },
    {
      path: "/work/detail/:id",
      name: "DetailWork",
      component: () => import("@/views/work/main/detail.vue"),
      meta: {
        title: "Detail Work",
      },
    },

    {
      path: "/work/location",
      name: "WorkLocation",
      component: () => import("@/views/work/location/index.vue"),
      meta: {
        title: "Work Location",
      },
    },
    {
      path: "/work/location/create",
      name: "CreateWorkLocation",
      component: () => import("@/views/work/location/create.vue"),
      meta: {
        title: "Create Work Location",
      },
    },
    {
      path: "/work/location/edit/:id",
      name: "EditWorkLocation",
      component: () => import("@/views/work/location/edit.vue"),
      meta: {
        title: "Edit Work Location",
      },
    },

    {
      path: "/work/material",
      name: "WorkMaterial",
      component: () => import("@/views/work/material/index.vue"),
      meta: {
        title: "Work Material",
      },
    },
    {
      path: "/work/material/create",
      name: "CreateWorkMaterial",
      component: () => import("@/views/work/material/create.vue"),
      meta: {
        title: "Create Work Material",
      },
    },
    {
      path: "/work/material/edit/:id",
      name: "EditWorkMaterial",
      component: () => import("@/views/work/material/edit.vue"),
      meta: {
        title: "Edit Work Material",
      },
    },
  ],
};
