<template>
  <div class="table-title flex-between">
    <slot></slot>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  name: "TableTitle",
};
</script>

<style lang="scss" scoped>
.table-title {
  padding: 12px 0;
}
</style>
