import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// register global common CSS
import "@/styles/index.scss";
import "@/styles/theme.scss";

import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/zh-TW";
import "element-ui/lib/theme-chalk/index.css";

// handle route permission
import "./permission";

// register global custom component
import MenuBreadcrumb from "@/components/MenuBreadcrumb/index.vue";
import TableTitle from "@/components/TableTitle/index.vue";
import TablePagination from "@/components/TablePagination/index.vue";
import { formatTime } from "@/utils/time";

import i18next from "i18next";
window.i18next = i18next;

// prevent non-passive event browser's warning
import "default-passive-events";

Vue.component("menu-breadcrumb", MenuBreadcrumb);
Vue.component("table-title", TableTitle);
Vue.component("table-pagination", TablePagination);

Vue.use(ElementUI, { locale });

// register vue global event
Vue.prototype.$formatTime = formatTime;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
