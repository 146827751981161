import Layout from "@/layout/index.vue";

export default {
  path: "/activity",
  component: Layout,
  meta: {
    roles: ["admin", "activity-manager"],
  },
  children: [
    {
      path: "/activity",
      name: "Activity",
      component: () => import("@/views/activity/main/index.vue"),
      meta: {
        title: "Activity",
      },
    },
    {
      path: "/activity/create",
      name: "CreateActivity",
      component: () => import("@/views/activity/main/create.vue"),
      meta: {
        title: "Create Activity",
      },
    },
    {
      path: "/activity/edit/:id",
      name: "EditActivity",
      component: () => import("@/views/activity/main/edit.vue"),
      meta: {
        title: "Edit Activity",
      },
    },
    {
      path: "/activity/detail/:id",
      name: "DetailActivity",
      component: () => import("@/views/activity/main/detail.vue"),
      meta: {
        title: "Detail Activity",
      },
    },

    {
      path: "/activity/category",
      name: "ActivityCategory",
      component: () => import("@/views/activity/category/index.vue"),
      meta: {
        title: "Activity Category",
      },
    },
    {
      path: "/activity/category/create",
      name: "CreateActivityCategory",
      component: () => import("@/views/activity/category/create.vue"),
      meta: {
        title: "Create Activity Category",
      },
    },
    {
      path: "/activity/category/edit/:id",
      name: "EditActivityCategory",
      component: () => import("@/views/activity/category/edit.vue"),
      meta: {
        title: "Edit Activity Category",
      },
    },
    {
      path: "/activity/category/detail/:id",
      name: "DetailActivityCategory",
      component: () => import("@/views/activity/category/detail.vue"),
      meta: {
        title: "Detail Activity Category",
      },
    },

    {
      path: "/activity/subcategory/create/:id",
      name: "CreateActivitySubcategory",
      component: () =>
        import("@/views/activity/category/create-subcategory.vue"),
      meta: {
        title: "Create Activity Subcategory",
      },
    },
    {
      path: "/activity/subcategory/edit/:cid/:scid",
      name: "EditActivitySubcategory",
      component: () => import("@/views/activity/category/edit-subcategory.vue"),
      meta: {
        title: "Edit Activity Subcategory",
      },
    },

    {
      path: "/activity/speaker",
      name: "Speaker",
      component: () => import("@/views/activity/speaker/index.vue"),
      meta: {
        title: "Speaker",
      },
    },
    {
      path: "/activity/speaker/create",
      name: "CreateSpeaker",
      component: () => import("@/views/activity/speaker/create.vue"),
      meta: {
        title: "Create Speaker",
      },
    },
    {
      path: "/activity/speaker/edit/:id",
      name: "EditSpeaker",
      component: () => import("@/views/activity/speaker/edit.vue"),
      meta: {
        title: "Edit Speaker",
      },
    },
  ],
};
