import Vue from "vue";
import VueRouter from "vue-router";
import accountRouter from "./modules/account";
import activityRouter from "./modules/activity";
import categoryRouter from "./modules/category";
import workRouter from "./modules/work";
import groupRouter from "./modules/group";
import scheduleRouter from "./modules/schedule";
import participantRouter from "./modules/participant";
import keywordRouter from "./modules/keyword";
import artistRouter from "./modules/artist";

Vue.use(VueRouter);

// Fix issue: https://github.com/PanJiaChen/vue-element-admin/issues/3243
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

export const constantRoutes = [
  {
    path: "/404",
    name: "ErrorPage404",
    component: () => import("@/views/error-page/404.vue"),
    meta: {
      title: "404",
    },
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "Login",
    },
  },
];

export const asyncRoutes = [
  accountRouter,
  activityRouter,
  categoryRouter,
  workRouter,
  groupRouter,
  scheduleRouter,
  participantRouter,
  keywordRouter,
  artistRouter,

  { path: "/", redirect: "/account" },

  { path: "*", redirect: "/404" },
];

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0, x: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();

  // ref: https://github.com/vuejs/vue-router/issues/2844
  // @ts-ignore
  router.matcher = newRouter.matcher; // reset router
}

export default router;
