import { getInfo, login } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";

const state = {
  token: getToken(),
  username: "",
  avatar: "",
  phone: "",
  name: "",
  role: "",
  active: true,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
  SET_NAME: (state, name_zh) => {
    state.name = name_zh;
  },
  SET_ROLE: (state, role) => {
    state.role = role;
  },
  SET_ACTIVE: (state, active) => {
    state.active = active;
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone;
  },
};

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then((response) => {
          commit("SET_TOKEN", response);
          setToken(response);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const { username, name, role, active, phone } = response["user"];

          commit("SET_USERNAME", username);
          commit("SET_NAME", name);
          commit("SET_ROLE", role);
          commit("SET_ACTIVE", active);
          commit("SET_PHONE", phone);

          resolve({ role });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_USERNAME", "");
      commit("SET_NAME", "");
      commit("SET_ROLE", "");
      commit("SET_ACTIVE", true);
      commit("SET_PHONE", "");
      commit("SET_TOKEN", "");

      removeToken();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
