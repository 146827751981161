import Layout from "@/layout/index.vue";

export default {
  path: "/group",
  component: Layout,
  meta: {
    roles: ["admin", "work-manager"],
  },
  children: [
    {
      path: "/group",
      name: "Group",
      component: () => import("@/views/group/main/index.vue"),
      meta: {
        title: "Group",
      },
    },
    {
      path: "/group/create",
      name: "CreateGroup",
      component: () => import("@/views/group/main/create.vue"),
      meta: {
        title: "Create Group",
      },
    },
    {
      path: "/group/edit/:id",
      name: "EditGroup",
      component: () => import("@/views/group/main/edit.vue"),
      meta: {
        title: "Edit Group",
      },
    },
    {
      path: "/group/detail/:id",
      name: "DetailGroup",
      component: () => import("@/views/group/main/detail.vue"),
      meta: {
        title: "Detail Group",
      },
    },
  ],
};
