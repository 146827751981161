<template>
  <div class="breadcrumb-component">
    <div class="container">
      <el-breadcrumb separator="/" class="custom-breadcrumb">
        <slot></slot>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuBreadcrumb",
};
</script>

<style lang="scss" scoped>
.breadcrumb-component {
  background-color: var(--breadcrumb-bg);
  margin-bottom: 20px;

  .custom-breadcrumb {
    padding: 20px 0;
  }
}
</style>
