import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import router, { resetRouter } from "@/router";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 18000,
});

service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    Message({
      message: error.response.data.message || error.message,
      type: "error",
      duration: 5 * 1000,
    });

    // Unauthorized error
    if (error.response.status === 401) {
      await store.dispatch("user/resetToken");
      resetRouter();
      await router.push({ name: "Login" });
    }

    return Promise.reject(error);
  }
);

export default service;
