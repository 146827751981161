<template>
  <nav class="navbar">
    <div class="container">
      <ul class="menu-list-group">
        <li v-if="handleDisabledRoute()">
          <router-link :to="{ name: 'Account' }" class="menu-link"
            >帳號管理</router-link
          >
        </li>
        <li v-if="handleDisabledRoute(['activity-manager'])">
          <el-dropdown>
            <el-button
              class="menu-link dropdown-button"
              type="text"
              :class="handleActiveClass('/activity')"
              @click="$router.push({ name: 'Activity' })"
              >活動管理</el-button
            >
            <el-dropdown-menu class="navbar-dropdown">
              <el-dropdown-item>
                <router-link :to="{ name: 'Activity' }" class="menu-link"
                  >活動管理</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link :to="{ name: 'Speaker' }" class="menu-link"
                  >講者管理</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link
                  :to="{ name: 'ActivityCategory' }"
                  class="menu-link"
                  >類別管理</router-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li v-if="handleDisabledRoute(['work-manager'])">
          <el-dropdown>
            <el-button
              class="menu-link dropdown-button"
              type="text"
              :class="handleActiveClass('/work')"
              @click="$router.push({ name: 'Work' })"
              >作品管理</el-button
            >
            <el-dropdown-menu class="navbar-dropdown">
              <el-dropdown-item>
                <router-link :to="{ name: 'Work' }" class="menu-link"
                  >作品管理</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link :to="{ name: 'WorkMaterial' }" class="menu-link"
                  >媒材管理</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link :to="{ name: 'WorkLocation' }" class="menu-link"
                  >展區管理</router-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>

        <li v-if="handleDisabledRoute(['work-manager'])">
          <router-link :to="{ name: 'Group' }" class="menu-link">
            團隊管理
          </router-link>
        </li>

        <li v-if="handleDisabledRoute(['work-manager'])">
          <router-link :to="{ name: 'Artist' }" class="menu-link">
            藝術家管理
          </router-link>
        </li>

        <li v-if="handleDisabledRoute(['work-manager'])">
          <router-link :to="{ name: 'Category' }" class="menu-link">
            類別管理
          </router-link>
        </li>

        <li v-if="handleDisabledRoute(['work-manager', 'activity-manager'])">
          <el-dropdown>
            <el-button
              class="menu-link dropdown-button"
              type="text"
              :class="handleActiveClass('/schedule')"
              @click="$router.push({ name: 'Schedule' })"
              >檔期管理</el-button
            >
            <el-dropdown-menu class="navbar-dropdown">
              <el-dropdown-item>
                <router-link :to="{ name: 'Schedule' }" class="menu-link"
                  >檔期管理</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link
                  :to="{ name: 'ScheduleLocation' }"
                  class="menu-link"
                  >展區管理</router-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li v-if="handleDisabledRoute(['activity-manager'])">
          <el-dropdown>
            <el-button
              class="menu-link dropdown-button"
              type="text"
              :class="handleActiveClass('/participant')"
              @click="$router.push({ name: 'Participant' })"
              >會員管理</el-button
            >
            <el-dropdown-menu class="navbar-dropdown">
              <el-dropdown-item>
                <router-link :to="{ name: 'Participant' }" class="menu-link"
                  >會員管理</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link
                  :to="{ name: 'ParticipantRecord' }"
                  class="menu-link"
                  >活動報名資料</router-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>

        <li>
          <router-link :to="{ name: 'Keyword' }" class="menu-link"
            >關鍵字管理</router-link
          >
        </li>

        <li style="margin-left: auto">
          <el-dropdown>
            <span class="el-dropdown-link menu-link">
              <el-button
                type="text"
                icon="el-icon-menu"
                style="padding: 0; font-size: 14px"
              ></el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleLogout"
                >登出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { resetRouter } from "@/router";
import { logout } from "@/api/user";

export default {
  name: "Navbar",
  computed: {
    currentPath() {
      return this.$route.path;
    },
    role() {
      return this.$store.getters.role;
    },
  },
  methods: {
    async handleLogout() {
      await logout();
      await this.$store.dispatch("user/resetToken");
      resetRouter();
      this.$message({
        type: "success",
        message: "登出成功",
      });
      await this.$router.push({ name: "Login" });
    },
    handleActiveClass(path) {
      return this.currentPath.startsWith(path) ? "active" : "";
    },
    handleDisabledRoute(roles = []) {
      if (this.role === "admin" || roles.includes(this.role)) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: var(--navbar-bg);

  .menu-list-group {
    display: flex;
  }

  .menu-link {
    padding: 20px;
    font-size: 14px;
    display: block;

    &.router-link-active {
      background-color: var(--navbar-link-active-bg);
      color: #ffffff;
    }
  }

  ::v-deep .el-dropdown {
    height: 100%;

    .menu-link {
      height: 100%;
      color: var(--primary-gray-color);
    }
  }

  .dropdown-button.active {
    background-color: var(--navbar-link-active-bg);
    color: #ffffff;
    border-radius: 0;
  }
}
</style>
