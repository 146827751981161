import Layout from "@/layout/index.vue";

export default {
  path: "/keyword",
  component: Layout,
  meta: {
    roles: ["admin", "activity-manager", "work-manager"],
  },
  children: [
    {
      path: "/keyword",
      name: "Keyword",
      component: () => import("@/views/keyword/index.vue"),
      meta: {
        title: "Keyword",
      },
    },
  ],
};
