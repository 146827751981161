import Layout from "@/layout/index.vue";

export default {
  path: "/account",
  component: Layout,
  meta: {
    roles: ["admin"],
  },
  children: [
    {
      path: "/account",
      name: "Account",
      component: () => import("@/views/account/index.vue"),
      meta: {
        title: "Account",
      },
    },
    {
      path: "/account/create",
      name: "CreateAccount",
      component: () => import("@/views/account/create.vue"),
      meta: {
        title: "Create Account",
      },
    },
    {
      path: "/account/edit/:id",
      name: "EditAccount",
      component: () => import("@/views/account/edit.vue"),
      meta: {
        title: "Edit Account",
      },
    },
  ],
};
